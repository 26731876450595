<template>
  <div class="root">
   
  </div>
</template>
<script>
import { getCrmMenu } from '@/api/api'
export default {
  data() {
    return {
      
    }
  },
  methods: {
    
  },
  async mounted() {

  },
}
</script>
<style lang="less" scoped>
.root {
  background-color: #F1F4FB;
}
</style>