import request from "@/utils/request";

// 权益查询
export function queryEquityByCustomerCode(data) {
    return request({
        url: '/merge/THEquity/queryEquityByCustomerCode',
        method: 'post',
        data
    })
}
// 请求数据
export function getMenu(data) {
    return request({
        url: '/merge/MenuInfo',
        method: 'post',
        data
    })
}
// 请求crm数据
export function getCrmMenu(data) {
    return request({
        url: '/merge/CrmMenuInfo',
        method: 'post',
        data
    })
}
export function quickLogin(data) {
    return request({
        url: '/merge/mall/quickLogin',
        method: 'get',
        data
    })
}
//验证码
export function getMassge(data) {
    return request({
        url: '/merge/Common/getCode',
        method: 'post',
        data
    })
}
// 登录
export function login(data) {
    return request({
        url: '/merge/Common/LoginWithCode',
        method: 'post',
        data
    })
}
// 跳转后登录
export function LoginWithOutCode(data) {
    return request({
        url: '/merge/Common/LoginWithOutCode',
        method: 'post',
        data
    })
}
//所有下拉框值
export function getSelect(data) {
    return request({
        url: '/merge/Common/getDict',
        method: 'get',
        data
    })
}
// 权益下拉框
export function getDictAnother(data) {
    return request({
        url: '/merge/Common/getDictAnother',
        method: 'get',
        data
    })
}
// 产品查询
export function getProduct(data) {
    return request({
        url: '/merge/productInfo',
        method: 'post',
        data
    })
}
//产品类型查询
export function getProductType(data){
    return request({
        url:'/merge/productType',
        method:'post',
        data
    })
}
// 我的页面健康档案成员查询
export function getFamilyInfo(data) {
    return request({
        url: '/merge/THCustomer/healthInfoQuery',
        method: 'post',
        data
    })
}
// 健康权益查询
export function healthInfoQueryEquity(data) {
    return request({
        url: '/merge/THCustomer/healthInfoQueryEquity',
        method: 'post',
        data
    })
}
// 我的页面健康档案成员删除
export function getFamilyDelete(data) {
    return request({
        url: '/merge/THCustomer/healthInfoDelete',
        method: 'post',
        data
    })
}
// 我的页面健康档案成员添加修改
export function getFamilyAddEdit(data) {
    return request({
        url: '/merge/THCustomer/healthInfoCreate',
        method: 'post',
        data
    })
}
// 我的页面健康档案成员根据id查单人
export function getFamilyInfoOne(data) {
    return request({
        url: '/merge/THCustomer/healthInfoQueryOne',
        method: 'post',
        data
    })
}
//门诊信息上传
export function uploadDataInfo(data) {
    return request({
        url: '/merge/healthRecord/addRecord',
        method: 'post',
        data
    })
}
//健康档案历史记录
export function historyInfo(data) {
    return request({
        url: '/merge/healthRecord/queryRecord',
        method: 'post',
        data
    })
}
//健康档案历史记录查详情 
export function historyInformation(data) {
    return request({
        url: '/merge/healthRecord/queryRecordOne',
        method: 'post',
        data
    })
}
//健康档案历史记录删除
export function historyInfoDelete(data) {
    return request({
        url: '/merge/healthRecord/delRecordOne',
        method: 'post',
        data
    })
}
//我的订单查询
export function myOrderQuery(data) {
    return request({
        url: '/merge/payMentOrder/selectOrder',
        method: 'post',
        data
    })
}
//我的订单取消订单
export function cancelOrder(data) {
    return request({
        url: '/merge/pay/cancelOrder',
        method: 'post',
        data
    })
}
//我的订单支付
export function orderPay(data) {
    return request({
        url: '/merge/pay/payByOrderId',
        method: 'post',
        data
    })
}
//报告解读接口
export function reportInterpretation(data) {
    return request({
        url: '/merge/consult/add',
        method: 'post',
        data
    })
}
//报告解读查询接口
export function reportQuery(data) {
    return request({
        url: '/merge/consult/query',
        method: 'post',
        data
    })
}
//报告解读查询接口
export function reportQueryOne(data) {
    return request({
        url: '/merge/consult/queryOne',
        method: 'post',
        data
    })
}
// 查询是否有权益
export function checkConsult(data) {
    return request({
        url: '/merge/consult/checkConsult',
        method: 'post',
        data
    })
}
// vip查询
export function getVipInfo(data) {
    return request({
        url: '/merge/getVipInfo',
        method: 'post',
        data
    })
}
// vip购买
export function buyVIP(data) {
    return request({
        url: '/merge/pay/buyVIP',
        method: 'post',
        data
    })
}
// 我的页面会员查询
export function checkVIP(data) {
    return request({
        url: '/merge/THEquity/queryVIPEquityByCustomerCodeRecord',
        method: 'post',
        data
    })
}
// 普惠好药领取权益
export function binding(data) {
    return request({
        url: '/merge/GiftCard/binding',
        method: 'post',
        data
    })
}
// 记账本登录
export function bookLogin(data) {
    return request({
        url: '/merge/sales/LoginWithPassword',
        method: 'post',
        data
    })
}
//查询销售人员列表
export function querySales(data) {
    return request({
        url: '/merge/sales/querySales',
        method: 'post',
        data
    })
}
//查询订单概览
export function queryOrderView(data) {
    return request({
        url: '/merge/sales/queryOrderBySalesCode',
        method: 'post',
        data
    })
}
//查询订单详情
export function queryOrderDetail(data) {
    return request({
        url: '/merge/sales/queryOrderDetail',
        method: 'post',
        data
    })
}
//微信获取签名
export function getWxSign(data) {
    return request({
        url: `/merge/wx/getSign`,
        method: 'post',
        data
    })
}