<template>
  <div class="root">
    <div class="card" v-for="(item,index) in 10">
      <div class="plan-name"></div>
      <div class="icon">
        <div class="share"></div>
        <div class="remove"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCrmMenu } from '@/api/api'
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  async mounted() {

  },
}
</script>
<style lang="less" scoped>
.root {
  background-color: #F1F4FB;
}
</style>