<template>
  <div class="root">
    <div class="top" :style="{ backgroundImage: `url(${back})` }">
      <div class="avatar">
        <img src="https://img.keaitupian.cn/newupload/11/1636445110881534.jpg">
      </div>
      <div class="user_info">
        <p class="name">梅狸喵</p>
        <p class="sign">今天也是元气满满的一天</p>
      </div>
    </div>
    <div class="middle">
      <div class="my_plan" :style="{ backgroundImage: `url(${myPlanLogo})` }" @click="goMyPlans">
        <p>我的方案</p>
      </div>
      <div class="cusInfo" :style="{ backgroundImage: `url(${cusInfoLogo})` }" @click="goMyCustomer">
        <p>客户信息</p>
      </div>
      <div class="book" :style="{ backgroundImage: `url(${bookAccountLogo})` }" @click="goAccountBook">
        <p>记账本</p>
      </div>
      <div class="opp" :style="{ backgroundImage: `url(${opportunityLogo})` }" @click="goMyChance">
        <p>商机</p>
      </div>
    </div>
    <div class="footer" @click="logout">
      <p>退出登录</p>
    </div>
    <van-notify v-model="show" type="success">
      <van-icon name="bell" style="margin-right: 4px;" />
      <span>退出成功</span>
    </van-notify>
  </div>
</template>
<script>
import { getCrmMenu } from '@/api/api'
import { Dialog } from 'vant';
export default {
  data() {
    return {
      show:false,
      back: '',
      bookAccountLogo: '',
      cusInfoLogo: '',
      myPlanLogo: '',
      opportunityLogo: '',
    }
  },
  methods: {
    //获取菜单信息
    async getMenuInfo() {
      let res = await getCrmMenu({ productCode: 'PC_CFUQHIVCWXHS' })
      this.back = res.data.back
      this.bookAccountLogo = res.data.bookAccount
      this.cusInfoLogo = res.data.cusInfo
      this.myPlanLogo = res.data.myPlan
      this.opportunityLogo = res.data.opportunity
    },
    logout() {
      Dialog.confirm({
        message: '确认退出登录？',
      })
        .then(() => {
          // on confirm
          this.show = true
          localStorage.removeItem('bookToken')
          setTimeout(() => {
            this.$router.push({ name: 'bookLogin' })
          }, 500)
        })
        .catch(() => {
          // on cancel
        });
    },
    goAccountBook(){
      this.$router.push({ name: 'bookHome' })
    },
    goMyPlans(){
      this.$router.push({name: 'crmMyPlans'})
    },
    goMyCustomer(){
      this.$router.push({name: 'crmMyCustomer'})
    },
    goMyChance(){
      this.$router.push({name: 'crmChance'})
    }
  },
  async mounted() {
    this.getMenuInfo();
  },
}
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.root {
  background-color: #F1F4FB;
  height: 100vh;

  .top {
    display: flex;
    align-items: center;
    height: 30vh;
    background-size: cover;

    .avatar {
      img {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        margin-left: 1rem;
        margin-top: -4rem;
        border: 3px solid #fff;
      }
    }

    .user_info {
      margin-left: 1rem;
      margin-top: -4rem;

      .name {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .sign {
        font-size: 12px;
        color: gray;
      }
    }
  }

  .middle {
    height: 22vh;
    background-color: #fff;
    width: 92%;
    margin: 0 auto;
    border-radius: 10px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
    margin-top: -6rem;

    .my_plan,
    .cusInfo,
    .book,
    .opp {
      border-radius: 5px;
      background-size: cover;
      width: 49%;
      height: 46%;
      display: flex;
      align-items: center;
      justify-content: baseline;

      p {
        margin-left: 1.5rem;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }

  .footer {
    margin: 0 auto;
    margin-top: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(65, 216, 159);
    width: 95%;
    height: 3rem;
    border-radius: 100px;

    p {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>