<template>
  <div class="root">
    <div class="list">
      <div class="item" v-for="item in 30">
        <p>产品xxxxxxxx</p>
        <van-icon class="icon" name="delete-o" size="30" color="red" @click="removeItem" />
      </div>
    </div>
    <div class="btn">
      <div class="inner" @click="openDialog">
        <p>制定方案</p>
      </div>
    </div>
    <!-- dialog -->
    <van-dialog v-model="dialogShow" @confirm="dialogConfirm" title="制定方案" show-cancel-button>
      <van-cell-group>
        <van-field maxlength="25" v-model="planName" label="输入方案名称" placeholder="方案名称不可重复" />
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script>
import { getMenu } from '@/api/api'
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: 0,
      dialogShow: false,
      planName:''
    }
  },
  methods: {
    //获取菜单信息
    async getMenuInfo() {

    },
    removeItem() {
      Toast.success('删除成功')
    },
    openDialog() {
      this.dialogShow = true
    },
    dialogConfirm(){
      if(this.planName == ''){
        Toast.fail('方案名称不可为空'); 
        return;
      }
      Toast.success('制定成功');
    }
  },
  async mounted() {
    console.log('crmplans');
  },
}
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.root {
  background-color: #F1F4FB;
  width: 100%;

  .list {
    padding-top: 1rem;
    padding-bottom: 12rem;

    .item {
      width: 95%;
      height: 3rem;
      background-color: #fff;
      border-radius: 5px;
      margin: 0 auto;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        margin-right: 1rem;
      }

      p {
        margin-left: 1rem;
        font-size: 16px;
      }
    }
  }

  .btn {
    position: fixed;
    bottom: 4rem;
    height: 5rem;
    width: 100%;
    background-color: #F1F4FB;

    .inner {
      margin: 0 auto;
      margin-top: 1rem;
      width: 70%;
      background-color: rgb(65, 216, 159);
      height: 3rem;
      border-radius: 100px;

      p {
        font-size: 18px;
        color: #fff;
        text-align: center;
        line-height: 3rem;
      }
    }
  }
}
</style>