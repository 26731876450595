<template>
  <div class="root">
    <div class="header">
      <div class="search">
        <van-search v-model="keywords" show-action placeholder="输入关键字搜索" background="#F1F4FB" @search="onSearch">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="filter">
        <van-tabs @click="onClick" v-model="activeName" background="#F1F4FB" color="rgb(2, 192, 193)"
          title-inactive-color="#C0C0C0" title-active-color="rgb(2, 192, 193)">
          <van-tab title="保险" name="0"></van-tab>
          <van-tab title="体检" name="1"></van-tab>
          <van-tab title="健管" name="2"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="main">
      <div class="card" v-for="item in 30">
        <img :src="productLogo" @click="showAction">
        <div class="bottom">
          <div class="left">
            <div class="name">
              <p>健康xxxx</p>
            </div>
            <div class="price">
              <p>￥100</p>
            </div>
          </div>
          <div class="right" @click="addToPlan">
            <van-icon name="add-o" size="20" color="rgb(2, 192, 193)" />
          </div>
        </div>
      </div>
    </div>
    <!-- 产品详情面板 -->
    <van-action-sheet v-model="actionShow" title="产品详情">
      <div class="action-content">
        <div class="head">
          <div class="img">
            <img :src="productLogo">
          </div>
          <div class="info">
            <p class="price">￥100</p>
            <p class="name">产品名称xxxx</p>
          </div>
        </div>
        <div class="middle">
          <p>年龄范围：xxx</p>
          <p>保额：xxxx</p>
          <p>等待期：xxx</p>
          <p>犹豫期：xxx</p>
          <p>地域销售范围：xxx</p>
        </div>
        <div class="bottom"  @click="addToPlan">
          <p>添加到我的方案</p>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { getCrmMenu } from '@/api/api'
import { Toast } from 'vant';
export default {
  data() {
    return {
      keywords: '',
      activeName: 'a',
      productLogo: '',
      actionShow: false,
    }
  },
  methods: {
    //获取菜单信息
    async getMenuInfo() {
      let res = await getCrmMenu({ productCode: 'PC_CFUQHIVCWXHS' })
      this.productLogo = res.data.productLogo
    },
    async onSearch() {

    },
    onClick(name, title) {
      console.log(name);
    },
    showAction(){
      this.actionShow = true
    },
    addToPlan(){
      this.actionShow = false
      Toast.success('添加成功');
    },
  },
  async mounted() {
    this.getMenuInfo()
  },
}
</script>
<style lang="less" scoped>

.root {
  background-color: #F1F4FB;
  margin: 1rem 0;
  position: relative;

  .header {
    width: 100vw;
    background-color: #F1F4FB;
    position: fixed;
    padding-top: 1rem;
    top: 0;
    z-index: 999;
  }

  .main {
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    .card {
      width: 46%;
      height: 8rem;
      margin-top: 0.5rem;
      border-radius: 10px;
      background-color: #fff;
      overflow: hidden;

      img {
        width: 100%;
        height: 5rem;
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          margin-top: -10px;
          margin-left: 10px;

          .name {
            p {
              font-size: 14px;
              font-weight: bold;
            }
          }

          .price {
            margin-top: -11px;

            p {
              font-size: 12px;
              color: red;
            }
          }
        }

        .right {
          margin-right: 10px;
        }
      }
    }
  }
  .action-content{
    padding: 16px 16px 50px;
    .head{
      display: flex;
      align-items: center;
      gap: 2rem;
      .img img{
        width: 180px;
      }
      .info{
        .price{
          font-size: 24px;
          color: red;
        }
      }
    }
    .middle{
      padding-top: 5px;
    }
    .bottom{
      margin: 0 auto;
      width: 90%;
      background-color: rgb(65,216,159);
      height: 2.5rem;
      border-radius: 100px;
      margin-top: 3rem;
      p{
        color: #fff;
        font-size: 18px;
        text-align: center;
        line-height: 2.5rem;
      }
    }
  }
}

.van-search .van-search__action {
  color: rgb(2, 192, 193)
}
</style>