<template>
  <div class="root">
    <router-view />
    <van-tabbar route v-model="active"  @change="onTabbarChange" active-color="rgb(23,172,186)" inactive-color="rgb(95,95,95)">
      <van-tabbar-item replace to="/crm/products" icon="shop-o">产品分类</van-tabbar-item>
      <van-tabbar-item replace to="/crm/crmPlans" icon="balance-list-o">制定方案</van-tabbar-item>
      <van-tabbar-item replace to="/crm/crmMine" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { getCrmMenu } from '@/api/api'
export default {
  data() {
    return {
      active: 0
    }
  },
  methods: {
    //标签栏切换事件
    onTabbarChange(name){
      // console.log(name);
    },
    //获取菜单信息
    async getMenuInfo() {

    },
  },
  async mounted() {

  },
}
</script>
<style lang="less" scoped>
.van-tabbar{
  height: 4rem;
}
</style>